import React from "react";
import { Tooltip, Typography, Menu } from "antd";
import variables from "styles/variables";
import {
	DownloadOutlined,
	SyncOutlined,
	CheckCircleTwoTone,
	CheckCircleFilled,
	InfoCircleFilled,
} from "@ant-design/icons";
import IntlMessages from "components/utility/intlMessages";
import { notification } from "antd";
import successIcon from "assets/images/success.svg";
import failureIcon from "assets/images/failure.svg";
import { fetchCompanyId, getLocaleMessages, isValid } from "redux/helper";
import { camelCase, capitalize, debounce, startCase } from "lodash";
import moment from "moment";
import momentTimezone from "moment-timezone";
import {
	RULE_NODE_TYPE,
	EVENT_NODE_TYPE,
	ADD_NEW_NODE_NODE_TYPE,
	ADD_RULE_ACTION,
	NO_ACTION,
	ADD_EVENT_ACTION,
	ADD_EVENT_OR_RULE_PROMPT_ACTION,
	ADD_NEW_NODE_NODE_HEIGHT,
	RULE_NODE_HEIGHT,
	EVENT_NODE_HEIGHT,
	RULE_NODE_SPACE,
	EVENT_NODE_SPACE,
	ADD_NEW_NODE_NODE_SPACE,
	SPACE_BETWEEN_NODES,
	dayNodeType,
	defaultColumns,
	chartData,
	hour12TimeFormat,
	approveText,
	scheduleTimeMinute,
	dateFields,
	emailFields,
	phoneFields,
	phoneRegex,
	defaultFields,
	nameRegex,
	nameFields,
	assetPage,
	assetMessage,
	assetTemplate,
	dateFormat,
	infoFields,
	assetTypeEmail,
	emailDetails,
	smsDetails,
	assetTypeSms,
	taskDetails,
	assetTypeTask,
	meetingDetails,
	lastNameRegex,
	attachmentImage,
	pdfImage,
	textFileImage,
	htmlFileImage,
	customToolVideo,
	assetsTaskTemplate,
	employeeRole,
	searchTimeout,
	emailRegex,
	subDomainText,
	portalText,
	companyText,
	userText,
	roleText,
	defaultPage,
	appSuperAdmin,
	assetTask,
	permissionManage,
	importText,
	journeyText,
	assetTypeMeeting,
	trelloDetails,
	assetTypeTrello,
	slackDetails,
	assetTypeTicketing,
	assetTypeMicrosoftTeams,
	microsoftTeamDetails,
	assetMicrosoftTeams,
	assetTicketing,
} from "components/constants";
import { store } from "redux/store";
import { cloneDeep } from "lodash";
import journeyActions from "redux/journeys/actions";
import employeesActions from "redux/employees/actions";
import settings from "config/settings";
import CryptoJS from "crypto-js";
import employeeActions from "redux/employee/actions";
import authActions from "redux/auth/actions";
import taskActions from "redux/Task/actions";
import assetActions from "redux/assets/actions";
import journeyTemplateActions from "redux/adminUser/journeys/actions";
import InjectMessage from "components/utility/intlMessages";
import rolesActions from "redux/settings/rolesAndPermissions/actions";
import meetingActions from "redux/meetings/actions";
import actions from "redux/Task/actions";
import taskTemplateActions from "redux/adminUser/tasks/actions";
import { useSelector } from "react-redux";
import trelloIcon from "../../assets/images/trello-logo.svg";

const { Text } = Typography;

export function showNotification({
	type,
	message,
	description,
	onClose,
	duration = 4.5,
	className = null,
}) {
	notification[type]({
		message: message,
		description: description,
		className:
			type === "error"
				? `error-notification ${className ? className : ""}`
				: "success-notification",
		icon: (
			<img src={type === "error" ? failureIcon : successIcon} alt={"icon"} />
		),
		onClose: onClose,
		duration: duration,
	});
}

export function getUploadStatus(status, record) {
	if (status === "error") {
		return (
			<>
				<Tooltip title={<IntlMessages id={"list.uploadFailure"} />}>
					<a
						className={"download-url"}
						download
						href={record.error_list_file_url}
					>
						<DownloadOutlined />
						<IntlMessages id={"label.failed"} />
					</a>
				</Tooltip>
			</>
		);
	} else if (status === "success") {
		return (
			<>
				<CheckCircleTwoTone
					className={"m-r-10"}
					twoToneColor={variables.SUCCESS_COLOR}
				/>
				<Text style={{ color: variables.SUCCESS_COLOR }}>
					<IntlMessages id={"label.success"} />
				</Text>
			</>
		);
	} else {
		return (
			<Text type={"warning"}>
				<SyncOutlined spin className={"m-r-10"} />
				<span>
					<IntlMessages id={"label.inProgress"} />
				</span>
			</Text>
		);
	}
}

export function getStagePercent(text) {
	if (text === "preboarding") {
		return { percent: 40, color: variables.DEFAULT_PROGRESS_COLOR };
	} else if (text === "onboarding") {
		return { percent: 70, color: variables.PROGRESS_PRIMARY_COLOR };
	} else {
		return { percent: 100, color: variables.SUCCESS_COLOR };
	}
}

export function getRootUrl() {
	return `company/${fetchCompanyId()}`;
}

export function constructAssetsToken(assetData) {
	let assetTokens = {};
	assetData.forEach((token) => {
		const isHRISToken = token.startsWith("employee:");
		const isATSToken = token.startsWith("application:");
		const isTicketingToken = token.startsWith("user:");
		const isTaskToken = token.startsWith("task:");
		const isDefaultToken = token.startsWith("squadsy:");
		const tokenParts = token.split(":");
		const name = `${
			isDefaultToken
				? startCase(tokenParts[1])
				: isHRISToken || isTicketingToken
				? tokenParts.map((part) => startCase(part)).join(" - ")
				: isTaskToken
				? tokenParts
						.splice(2)
						.map((part) => startCase(part))
						.join(" - ")
				: tokenParts
						.splice(1)
						.map((part) => startCase(part))
						.join(" - ")
		}`;
		const value = `{{${token}}}`;
		assetTokens[token] = {
			name,
			value,
		};
	});
	return [assetTokens];
}

export function getLocalTimeZone() {
	return momentTimezone.tz.guess();
}

export function getTimeWithTimeZone(date, dateFormat, getTimeZone) {
	return momentTimezone(date).tz(getTimeZone).format(dateFormat);
}

export function convertMomentToString(date, dateFormat) {
	return moment(date).format(dateFormat);
}

// journey json creation logic starts
export function generateJourneyJSON(rules, firstRuleID) {
	const journeyBuilderJSON = cloneDeep(chartData);
	if (rules.length == 0) {
		createNode(
			ADD_NEW_NODE_NODE_TYPE,
			ADD_NEW_NODE_NODE_TYPE,
			ADD_RULE_ACTION,
			{ parentRuleID: -1, nextRuleID: -1 },
			0,
		);
		return { chartData: journeyBuilderJSON };
	}

	let currentRuleID = firstRuleID;
	let rulePosition = 1;
	let prevRule = null;
	while (currentRuleID !== -1) {
		const rule = rules.find((rule) => rule.id === currentRuleID);
		if (rule) {
			let ruleYPosition =
				rulePosition === 1 ? 0 : getNodeYPosition(RULE_NODE_TYPE, prevRule);
			createNode(
				`${RULE_NODE_TYPE}_${rule.id}`,
				RULE_NODE_TYPE,
				NO_ACTION,
				{ rule, prevRuleID: prevRule ? prevRule.id : -1 },
				ruleYPosition,
			);
			if (rulePosition !== 1) {
				createLink(
					`${ADD_NEW_NODE_NODE_TYPE}_${prevRule.id}_${RULE_NODE_TYPE}_${rule.id}`,
					`${ADD_NEW_NODE_NODE_TYPE}_${prevRule.id}`,
					`${RULE_NODE_TYPE}_${rule.id}`,
				);
			}
			rule.events.forEach((event, index) => {
				const differedModuleKeys = {
					parentRuleID: rule.id,
					duration: event.event.duration,
					moduleType: event.event.asset_type,
					moduleName: event.event.name,
					time: event.scheduled_time,
					assetId: event.event.id,
					eventId: event.id,
					eventAssignTo: event.event.assign_to,
					content: event.event.content,
					files: event.event.files,
					assignTo: event.assign_to,
					taskDue: event.task_due,
					notificationPlatform: event.notification_platform,
					reminder: event.reminder,
					beforeDue: event.days_before_due,
					afterDueFrequency: event.days_after_due,
					meetingDescription: event.event.description,
					location: event.event.location,
					endTime: event.end_time,
					slackAsset: event.slack_asset_id,
					customEmails:
						event.event.custom_emails && event.event.custom_emails.length > 0
							? event.event.custom_emails
							: event.cc_emails,
					multipleTaskSubmission: event.multiple_task_submission,
					watchers: event.watchers,
					ticketingAssetId: event.event.ticketing_asset_id,
					projectId: event.event.merge_id,
					microsoftTeamAsset: event.microsoft_team_asset_id,
					flexibleConditions: event.flexible_conditions,
					sendFrom:
						event.send_from === undefined
							? null
							: event.send_from.address_type !== undefined &&
							  event.microsoft_team_asset_id === undefined
							? `${event.send_from.id}-${event.send_from.integration_type}`
							: event.send_from.id,
					aggregate: event.aggregate,
				};
				if (event.event.merge !== undefined) {
					differedModuleKeys.logo = event.event.merge.image;
					differedModuleKeys.ticketingMerge = event.event.merge.int_name;
				}
				createNode(
					`${EVENT_NODE_TYPE}_${event.id}_${rule.id}`,
					EVENT_NODE_TYPE,
					NO_ACTION,
					differedModuleKeys,
					getNodeYPosition(EVENT_NODE_TYPE, rule, index),
				);
				if (index === 0) {
					createLink(
						`${RULE_NODE_TYPE}_${rule.id}_${EVENT_NODE_TYPE}_${event.id}_${rule.id}`,
						`${RULE_NODE_TYPE}_${rule.id}`,
						`${EVENT_NODE_TYPE}_${event.id}_${rule.id}`,
					);
				} else {
					createLink(
						`${EVENT_NODE_TYPE}_${rule.events[index - 1].id}_${
							rule.id
						}_${EVENT_NODE_TYPE}_${event.id}_${rule.id}`,
						`${EVENT_NODE_TYPE}_${rule.events[index - 1].id}_${rule.id}`,
						`${EVENT_NODE_TYPE}_${event.id}_${rule.id}`,
					);
				}
			});
			if (rule.events.length === 0) {
				createNode(
					`${ADD_NEW_NODE_NODE_TYPE}_${rule.id}`,
					ADD_NEW_NODE_NODE_TYPE,
					ADD_EVENT_ACTION,
					{
						parentRuleID: rule.id,
						nextRuleID: rule.next_rule_id,
					},
					getNodeYPosition(
						ADD_NEW_NODE_NODE_TYPE,
						rule,
						rule.events.length - 1,
					),
				);
				createLink(
					`${RULE_NODE_TYPE}_${rule.id}_${ADD_NEW_NODE_NODE_TYPE}_${rule.id}`,
					`${RULE_NODE_TYPE}_${rule.id}`,
					`${ADD_NEW_NODE_NODE_TYPE}_${rule.id}`,
				);
			} else {
				createNode(
					`${ADD_NEW_NODE_NODE_TYPE}_${rule.id}`,
					ADD_NEW_NODE_NODE_TYPE,
					ADD_EVENT_OR_RULE_PROMPT_ACTION,
					{
						parentRuleID: rule.id,
						nextRuleID: rule.next_rule_id,
					},
					getNodeYPosition(
						ADD_NEW_NODE_NODE_TYPE,
						rule,
						rule.events.length - 1,
					),
				);
				createLink(
					`${EVENT_NODE_TYPE}_${rule.events[rule.events.length - 1].id}_${
						rule.id
					}_${ADD_NEW_NODE_NODE_TYPE}_${rule.id}`,
					`${EVENT_NODE_TYPE}_${rule.events[rule.events.length - 1].id}_${
						rule.id
					}`,
					`${ADD_NEW_NODE_NODE_TYPE}_${rule.id}`,
				);
			}
		}
		rulePosition++;
		currentRuleID = rule.next_rule_id;
		prevRule = rule;
	}

	function getPorts() {
		let port1 = {},
			port2 = {},
			properties = {
				properties: {
					linkColor: variables.PRIMARY_COLOR,
				},
			};

		port2 = {
			port2: {
				id: "port2",
				type: "output",
				...properties,
			},
		};
		port1 = {
			port1: {
				id: "port1",
				type: "input",
				...properties,
			},
		};
		return { ...port1, ...port2 };
	}

	//get the horizontal position of chart
	function getNodeXPosition(nodeType) {
		if (nodeType === RULE_NODE_TYPE) {
			return RULE_NODE_SPACE;
		} else if (nodeType === EVENT_NODE_TYPE) {
			return EVENT_NODE_SPACE;
		} else {
			return ADD_NEW_NODE_NODE_SPACE;
		}
	}

	//get the vertical position of chart
	function getNodeYPosition(nodeType, prevRule, eventIndex = -1) {
		switch (nodeType) {
			case RULE_NODE_TYPE: {
				let prevRuleYPosition =
					journeyBuilderJSON.nodes[`${RULE_NODE_TYPE}_${prevRule.id}`].position
						.y;
				let numEvents = prevRule.events.length;
				let numSpaces = numEvents + 2;
				return (
					prevRuleYPosition +
					RULE_NODE_HEIGHT +
					numEvents * EVENT_NODE_HEIGHT +
					numSpaces * SPACE_BETWEEN_NODES +
					ADD_NEW_NODE_NODE_HEIGHT
				);
			}
			case EVENT_NODE_TYPE: {
				if (eventIndex === 0) {
					let prevRuleYPosition =
						journeyBuilderJSON.nodes[`${RULE_NODE_TYPE}_${prevRule.id}`]
							.position.y;
					return prevRuleYPosition + RULE_NODE_HEIGHT + SPACE_BETWEEN_NODES;
				}
				let prevEventYPosition =
					journeyBuilderJSON.nodes[
						`${EVENT_NODE_TYPE}_${prevRule.events[eventIndex - 1].id}_${
							prevRule.id
						}`
					].position.y;
				return prevEventYPosition + EVENT_NODE_HEIGHT + SPACE_BETWEEN_NODES;
			}
			default: {
				let prevRuleYPosition =
					journeyBuilderJSON.nodes[`${RULE_NODE_TYPE}_${prevRule.id}`].position
						.y;
				return (
					prevRuleYPosition +
					RULE_NODE_HEIGHT +
					(eventIndex + 2) * SPACE_BETWEEN_NODES +
					(eventIndex + 1) * EVENT_NODE_HEIGHT
				);
			}
		}
	}

	function createLink(id, fromNode, toNode) {
		journeyBuilderJSON.links[id] = {
			id,
			from: {
				nodeId: fromNode,
				portId: "port2",
			},
			to: {
				nodeId: toNode,
				portId: "port1",
			},
		};
	}

	function createNode(id, nodeType, actionType, content, nodeYPosition) {
		journeyBuilderJSON.nodes[id] = {
			id,
			type: nodeType,
			actionType,
			...content,
			position: {
				x: getNodeXPosition(nodeType),
				y: nodeYPosition,
			},
			ports: getPorts(),
		};
	}

	return { chartData: journeyBuilderJSON };
}
// journey json creation logic ends

export function fetchCurrentJourneyID() {
	return store.getState().Journeys.currentJourneyID;
}
export function fetchCurrentJourneyTemplateID() {
	return store.getState().AdminJourney.currentJourneyID;
}

export function changeTimeFormat(time, current, changeTo) {
	return moment(time, [current]).format(changeTo);
}

export function getCurrentNode(node) {
	/* let day = node.day;
  if (node.id.includes("pre")) {
    day = -day;
  }
  return day; */
	return 0;
}

export function getObjectLength(object) {
	return Object.keys(object).length;
}

export function constructExtraColumn(columns) {
	return columns.filter((column) => !defaultColumns.includes(column));
}

export function constructCheckBox(columns) {
	return columns.map((column) => ({
		label: (
			<div className={"extra-column-label"}>
				{capitalize(column.split("_").join(" "))}
			</div>
		),
		value: column,
	}));
}

export function getLocalStorage(key) {
	let selectedColumns = [];
	if (localStorage.getItem(key) && localStorage.getItem(key).length) {
		selectedColumns = localStorage.getItem(key).split(",");
	}
	return selectedColumns;
}

export function getStage(status) {
	if (status === "unapproved") {
		return (
			<>
				<InfoCircleFilled
					className={"m-r-10"}
					style={{
						fontSize: variables.SQ_SPACING_BASE,
						color: variables.ICON_UNAPPROVED_BG_COLOR,
					}}
				/>
				<Text style={{ color: variables.TEXT_COLOR }}>
					<IntlMessages id={"label.unapproved"} />
				</Text>
			</>
		);
	} else {
		return (
			<>
				<CheckCircleFilled
					className={"m-r-10"}
					style={{
						fontSize: variables.SQ_SPACING_BASE,
						color: variables.SUCCESS_COLOR,
					}}
				/>
				<Text style={{ color: variables.TEXT_COLOR }}>
					<IntlMessages id={"label.approved"} />
				</Text>
			</>
		);
	}
}

export function getMomentTime(time, format) {
	return moment(time, format);
}

export function searchAssets(
	page,
	searchText,
	initialFetch,
	moduleContent,
	node,
) {
	store.dispatch({ type: journeyActions.SET_ASSET_PAGE, payload: page });
	store.dispatch({
		type: journeyActions.SET_ASSET_SEARCH,
		payload: searchText,
	});
	store.dispatch({
		type: journeyActions.FETCH_GMAIL_ADDRESS,
		initialFetch: true,
	});
	store.dispatch({
		type: journeyActions.FETCH_ASSETS,
		initialFetch,
		isSearch: true,
		moduleContent,
		node,
	});
}

export function searchTasks(
	page,
	searchText,
	initialFetch,
	moduleContent,
	node,
) {
	store.dispatch({ type: journeyActions.SET_TASK_PAGE, payload: page });
	store.dispatch({
		type: journeyActions.SET_TASK_SEARCH,
		payload: searchText,
	});
	store.dispatch({
		type: journeyActions.FETCH_JOURNEY_TASKS,
		initialFetch,
		isSearch: true,
		moduleContent,
		node,
	});
}

export function searchJourney(page, searchText, initialFetch) {
	store.dispatch({ type: employeesActions.SET_JOURNEY_PAGE, payload: page });
	store.dispatch({
		type: employeesActions.SET_JOURNEY_SEARCH,
		payload: searchText,
	});
	store.dispatch({
		type: employeesActions.FETCH_JOURNEY,
		initialFetch,
		isSearch: true,
	});
}

export function getModuleFields(eventType) {
	const { editModuleData, users, roles, customRelationsList, slackUsers } =
		store.getState().Journeys;
	let assignTo = [];

	if (editModuleData.eventAssignTo?.role_ids) {
		const roleNames = roles.filter((value) => {
			return editModuleData.eventAssignTo.role_ids.includes(value.id);
		});
		assignTo = assignTo.concat(roleNames.map((role) => startCase(role.name)));
	}
	if (editModuleData.eventAssignTo?.user_ids) {
		const userNames = users.filter((value) =>
			editModuleData.eventAssignTo.user_ids.includes(value.id),
		);
		assignTo = assignTo.concat(userNames.map((user) => user.email));
	}
	if (editModuleData.eventAssignTo?.employee) {
		assignTo = assignTo.concat(employeeRole.name);
	}
	if (editModuleData.eventAssignTo?.relation_ids) {
		const relationNames = customRelationsList.filter((relation) => {
			return editModuleData.eventAssignTo.relation_ids.includes(relation.id);
		});
		assignTo = assignTo.concat(
			relationNames.map((relation) => startCase(relation.field_name)),
		);
	}
	if (editModuleData.eventAssignTo?.slack_ids) {
		assignTo = assignTo.concat(
			slackUsers
				.filter((user) =>
					editModuleData.eventAssignTo.slack_ids.includes(user.id),
				)
				.map((user) => user.slack_user_id),
		);
	}

	if (editModuleData.eventAssignTo?.slack_channels) {
		assignTo = assignTo.concat(
			editModuleData.eventAssignTo.slack_channels.map((channel) => channel.id),
		);
	}
	return [
		{
			name: ["microsoftTeamAsset"],
			value: isHaveModule(editModuleData.microsoftTeamAsset),
		},
		{
			name: ["flexibleConditions"],
			value: editModuleData.flexibleConditions,
		},
		{
			name: ["combinator"],
			value: editModuleData.combinator,
		},
		{
			name: ["sendFrom"],
			value: editModuleData.sendFrom,
		},
		{
			name: ["asset"],
			value: isHaveModule(editModuleData.assetId),
		},
		{
			name: ["slackAsset"],
			value: editModuleData.slackAsset,
		},
		{
			name: ["ticketingAssetId"],
			value: isHaveModule(editModuleData.ticketingAssetId),
		},
		{
			name: ["scheduledTime"],
			value: editModuleData.time
				? getMomentTime(editModuleData.time, hour12TimeFormat)
				: "",
		},
		{
			name: ["assignTo"],
			value: assignTo.length ? assignTo : editModuleData.assignTo,
		},
		{
			name: ["taskDue"],
			value: isValid(editModuleData.taskDue)
				? editModuleData.taskDue
				: undefined,
		},
		{
			name: ["reminder"],
			value: editModuleData.reminder,
		},
		{
			name: ["beforeDue"],
			value:
				editModuleData.beforeDue && isValid(editModuleData.beforeDue)
					? editModuleData.beforeDue
					: undefined,
		},
		{
			name: ["afterDueFrequency"],
			value:
				editModuleData.afterDueFrequency &&
				isValid(editModuleData.afterDueFrequency)
					? editModuleData.afterDueFrequency
					: undefined,
		},
		{
			name: ["meetingName"],
			value: editModuleData.moduleName,
		},
		{
			name: ["description"],
			value: editModuleData.meetingDescription,
		},
		{
			name: ["location"],
			value: editModuleData.location,
		},
		{
			name: ["duration"],
			value: editModuleData.duration,
		},
		{
			name: ["custom_emails"],
			value: editModuleData.customEmails,
		},
		{
			name: ["watcher"],
			value: getWatchersList(
				editModuleData.watchers ? editModuleData.watchers : [],
			),
		},
		{
			name: ["projectId"],
			value: editModuleData.projectId,
		},
		{
			name: ["aggregate"],
			value: editModuleData.aggregate,
		},
	];
}

export function getWatchersList(watchers) {
	// get watchers as a single array from custom emails and user ids
	const { users } = store.getState().Journeys,
		watchersList = watchers.custom_emails ? watchers.custom_emails : [];
	return [
		...users.reduce((userList, user) => {
			if (watchers?.user_ids?.includes(user.id)) {
				return [...userList, user.email];
			}
			return userList;
		}, []),
		...watchersList,
	];
}

export function isHaveModule(editAssetId) {
	const { createdModule } = store.getState().Journeys;
	if (isValid(createdModule.id)) {
		return createdModule.id ? createdModule.id.toString() : undefined;
	} else {
		return isValid(editAssetId) ? editAssetId.toString() : undefined;
	}
}

export function setSelectedAsset(value) {
	store.dispatch({
		type: journeyActions.UPDATE_SELECTED_ASSET,
		payload: value || {},
	});
}

export function parseToInteger(value) {
	return parseInt(value);
}

export function getApprovalHeader(approvalStatus) {
	if (approvalStatus === approveText) {
		return "journey.approvalConfirm";
	} else {
		return "journey.unApproveConfirm";
	}
}

export function isValidTime(time) {
	//checks the time is in 15 minutes interval
	return moment(time).minute() % scheduleTimeMinute === 0;
}

export function constructPageURL(pagesData, href = false) {
	let pagesURl = {};
	pagesData.map((pageData) => {
		let name = `{{ '${pageData.name}' | page_link }}`;
		const splitToken = pageData.href.split("://app"),
			suDomainUrl = `${splitToken[0]}://${subDomainText}${splitToken[1]}`;
		pagesURl[pageData.name] = {
			name: capitalize(pageData.name),
			value: `<a
            class="link-builder"
            href="${href ? suDomainUrl : name}"
            target="_blank"
          >
            ${href ? suDomainUrl : name}
          </a>`,
		};
		return pageData;
	});
	return [pagesURl];
}

export function getTableHeight() {
	const totalPadding = 200,
		tableHeader = document.getElementsByClassName("list-table-header")[0]
			? document.getElementsByClassName("list-table-header")[0].clientHeight
			: 0,
		tablePagination = document.getElementsByClassName("ant-pagination")[0]
			? document.getElementsByClassName("ant-pagination")[0].clientHeight
			: 0,
		assetTab = document.getElementsByClassName("ant-tabs-top-bar")[0]
			? document.getElementsByClassName("ant-tabs-top-bar")[0].clientHeight
			: 0;
	return (
		window.innerHeight -
		(tableHeader + totalPadding + tablePagination + assetTab)
	);
}

export function getTableMaxWidth() {
	const totalPadding = 430,
		onBack = document.getElementsByClassName("back")[0]
			? document.getElementsByClassName("back")[0].clientWidth
			: 0,
		image = document.getElementsByClassName("image")[0]
			? document.getElementsByClassName("image")[0].clientWidth
			: 0,
		right = document.getElementsByClassName("right")[0]
			? document.getElementsByClassName("right")[0].clientWidth
			: 0,
		editSvg = document.getElementsByClassName("edit-svg")[0]
			? document.getElementsByClassName("edit-svg")[0].clientWidth +
			  variables.SPACE_15
			: 0,
		timeZone = document.getElementsByClassName("timezone-tag")[0]
			? document.getElementsByClassName("timezone-tag")[0].clientWidth +
			  variables.SPACE_20
			: 0;
	return (
		window.innerWidth -
		(onBack + totalPadding + image + right + editSvg + timeZone + 600)
	);
}

export function getTableMinWidth() {
	const totalPadding = 400,
		onBack = document.getElementsByClassName("back")[0]
			? document.getElementsByClassName("back")[0].clientWidth
			: 0,
		image = document.getElementsByClassName("image")[0]
			? document.getElementsByClassName("image")[0].clientWidth
			: 0,
		editSvg = document.getElementsByClassName("edit-svg")[0]
			? document.getElementsByClassName("edit-svg")[0].clientWidth +
			  variables.SPACE_15
			: 0,
		timeZone = document.getElementsByClassName("timezone-tag")[0]
			? document.getElementsByClassName("timezone-tag")[0].clientWidth +
			  variables.SPACE_20
			: 0,
		right = document.getElementsByClassName("right")[0]
			? document.getElementsByClassName("right")[0].clientWidth
			: 0;
	return (
		window.innerWidth -
		(onBack + totalPadding + image + timeZone + editSvg + right)
	);
}

export function getCurrentListID() {
	return store.getState().Employees.selectedListId;
}

export function getInitialFields(values) {
	let fields = [],
		object = {};
	for (const value of Object.keys(values)) {
		if (dateFields.includes(value)) {
			object = {
				name: value,
				value: values[value] !== null ? moment(values[value]) : undefined,
			};
		} else if (!isValid(values[value])) {
			object = { name: value, value: getLocaleMessages("label.unknown") };
		} else if (values[value] === false) {
			object = { name: value, value: getLocaleMessages("label.no") };
		} else if (value === "journey") {
			object = { name: value, value: [values[value].name] };
		} else {
			object = { name: value, value: values[value] };
		}
		fields = fields.concat(object);
	}
	if (values.custom_relations?.length > 0) {
		for (const item of values.custom_relations) {
			const field_type = item.field_type;
			if (field_type === "relation_name") {
				object = {
					name: `${item.id}_${field_type}_custom_${item.employee_custom_relation_id}`,
					value: item.employee_relation_id,
				};
			} else if (field_type === "date") {
				object = {
					name: `${item.id}_${field_type}_custom_${item.employee_custom_relation_id}`,
					value: item.value ? moment(item.value) : undefined,
				};
			} else {
				object = {
					name: `${item.id}_${field_type}_custom_${item.employee_custom_relation_id}`,
					value: item.value,
				};
			}
			fields = fields.concat(object);
		}
	}
	return fields;
}

export function getRules(value) {
	let rules = [];
	if (emailFields.includes(value)) {
		rules = rules.concat({
			type: "email",
			message: (
				<>
					<IntlMessages id={"message.invalid"} />{" "}
					<IntlMessages id={"label.email"} />
				</>
			),
		});
	}
	if (phoneFields.includes(value)) {
		rules = rules.concat(({ getFieldValue }) => ({
			validator(rule, value) {
				let newValue = value.trim();
				if (newValue !== "" && !newValue.match(phoneRegex)) {
					return Promise.reject(<IntlMessages id={"message.invalidPhone"} />);
				}
				return Promise.resolve();
			},
		}));
	}
	if (
		defaultFields.includes(value) &&
		!["start_date", "birth_date", "phone"].includes(value)
	) {
		rules = rules.concat({
			required: true,
			whitespace: true,
			message: <IntlMessages id={"message.fieldRequired"} />,
		});
	} else if (value === "manager_id" || value === "buddy_id") {
		rules = rules.concat({
			required: true,
			message: <IntlMessages id={"message.fieldRequired"} />,
		});
	}
	if (nameFields.includes(value)) {
		rules = rules.concat(() => ({
			validator(rule, value) {
				if (value) {
					let newValue = value.trim();
					if (newValue.length === 0) {
						return Promise.reject(
							<>
								<InjectMessage id={"label.first"} />{" "}
								<InjectMessage id={"message.nameMinimum"} />
							</>,
						);
					}
				}
				return Promise.resolve();
			},
		}));
	}
	return rules;
}

export function getTransformElement() {
	return document.getElementsByClassName("react-transform-component")[0];
}

export function disabledDateBeforeToday(current) {
	// Can not select days before today
	return current && current < moment().subtract(1, "day").endOf("day");
}

export function disabledDateAfterToday(current) {
	// Can not select days after today
	return current && current > moment().endOf("day");
}

export function getAssetsTotal(
	activeList,
	templateActiveList,
	totalMessageData,
	totalPagesData,
	totalTasks,
	totalTasksTemplate,
	totalMicrosoftTeamAssets,
	ticketingAssetsCount,
) {
	if (activeList === assetMessage) {
		return totalMessageData;
	} else if (activeList === assetPage) {
		return totalPagesData;
	} else if (activeList === assetTemplate) {
		if (templateActiveList === assetMessage) {
			return totalMessageData;
		} else if (templateActiveList === assetPage) {
			return totalPagesData;
		} else {
			return totalTasksTemplate;
		}
	} else if (activeList === assetsTaskTemplate) {
		return totalTasksTemplate;
	} else if (activeList === assetMicrosoftTeams) {
		return totalMicrosoftTeamAssets;
	} else if (activeList === assetTicketing) {
		return ticketingAssetsCount;
	} else {
		return totalTasks;
	}
}
export function isHasValue(key) {
	return !["", undefined].includes(key);
}

export function unLayerInit({
	editorId,
	displayType = "web",
	preview = true,
	enableForm = false,
}) {
	const defaultEmailSettings = {
		form: {
			enabled: enableForm,
		},
	};
	const editorOptions = {
		id: editorId,
		projectId: parseInt(settings.UNLAYER_PROJECT_KEY),
		features: {
			undoRedo: true,
			textEditor: {
				spellChecker: true,
			},
			preview,
		},
		customCSS: [
			`.tox-tiered-menu > div:last-child {
         width: 450px;
      }
      .tox-tiered-menu > div:last-child .tox-collection__item-label {
        white-space: normal;
        word-break: break-word;
      }
      .link-builder {
        margin-right: 5px;
        margin-left: 5px;
      }
      .tox-tiered-menu > div:first-child,
      .tox-tiered-menu > div:first-child .tox-menu-nav__js.tox-collection__item.tox-collection__item--state-disabled.tox-collection__item--active {
        width: 150px;
      }
      ${
				!preview
					? `.actions-container > div div:nth-child(3), .actions-container > div div:nth-child(4), .actions-container > div div:nth-child(5){
        display: none;
      }`
					: ""
			}`,
		],
		customJS: [`${customToolVideo}`],
		displayMode: displayType,
		linkTypes: [
			{
				name: "phone",
				enabled: false,
			},
			{
				name: "email",
				enabled: false,
			},
			{
				name: "sms",
				enabled: false,
			},
		],
	};
	window.unlayer.init({ ...editorOptions, ...defaultEmailSettings });
	window.onbeforeunload = () => true;
}
export function getTitle(currentEmployee, details) {
	if (infoFields.includes(details)) {
		if (details === "journey" && currentEmployee[details]) {
			return currentEmployee[details].name;
		} else if (details === "meetings" && currentEmployee[details]) {
			return currentEmployee[details].join();
		} else if (dateFields.includes(details)) {
			return convertMomentToString(currentEmployee[details], dateFormat);
		} else {
			return currentEmployee[details];
		}
	}
	return null;
}

export function getRoleType() {
	return localStorage.getItem("login_type");
}

export function getModuleDetails(addModuleType, ticketingMerges) {
	if (addModuleType === assetTypeEmail) {
		return emailDetails;
	} else if (addModuleType === assetTypeSms) {
		return smsDetails;
	} else if (addModuleType === assetTypeTask) {
		return taskDetails;
	} else if (addModuleType === assetTypeMeeting) {
		return meetingDetails;
	} else if (addModuleType === assetTypeTicketing) {
		return {
			label: "journey.selectPamphlet",
			heading: "journey.addModule.trello",
			icon: ticketingMerges.length ? ticketingMerges[0].image : null,
			addButton: "label.add",
			iconHeight: 20,
			moduleValidation: "journey.module.taskValidation",
			createModule: "journey.createTrello",
		};
	} else if (addModuleType === assetTypeMicrosoftTeams) {
		return microsoftTeamDetails;
	} else {
		return slackDetails;
	}
}

export function NameTicketingMerges(ticketingMerges) {
	if (ticketingMerges.length) {
		return ticketingMerges[0].int_name;
	} else {
		return null;
	}
}

export function getDueTitle(day) {
	if (day < 0) {
		return `${-day} ${getLocaleMessages("label.day")}${
			-day > 1 ? getLocaleMessages("label.plural") : ""
		} ${getLocaleMessages("journey.beforeStart")}`;
	} else if (day === 0) {
		return getLocaleMessages("journey.startDay");
	} else {
		return `${day} ${getLocaleMessages("label.day")}${
			day > 1 ? getLocaleMessages("label.plural") : ""
		} ${getLocaleMessages("journey.afterStart")}`;
	}
}

export function setTaskDues(currentDay) {
	// generate task due options from journey length
	const days = getNodeDays(),
		taskDues = days.filter((day) => day && day.id >= currentDay);
	store.dispatch({ type: journeyActions.UPDATE_TASK_DUES, payload: taskDues });
}

export function setReminderOptions(taskDue, journeyDay) {
	//generate options to send reminder before due date and frequency of after due date reached
	const days = getNodeDays(),
		beforeDueDates = days.filter(
			(day, index) => day && day.id < taskDue && day.id > journeyDay,
		),
		afterDueDates = days.filter((day) => day && day.id > taskDue);
	store.dispatch({
		type: journeyActions.SET_REMINDER_OPTIONS,
		beforeDueDates,
		afterDueDates,
	});
}
export function setReminderTemplateOptions(taskDue, journeyDay) {
	//generate options to send reminder before due date and frequency of after due date reached
	const days = getNodeTemplateDays(),
		beforeDueDates = days.filter(
			(day, index) => day && day.id < taskDue && day.id > journeyDay,
		),
		afterDueDates = days.filter((day) => day && day.id > taskDue);
	store.dispatch({
		type: journeyTemplateActions.SET_REMINDER_TEMPLATE_OPTIONS,
		beforeDueDates,
		afterDueDates,
	});
}

export function setMeetingReminders(currentDay) {
	// generate meeting reminder options from journey length
	const days = getNodeDays(),
		meetingReminders = days.filter((day) => day && day.id < currentDay);
	store.dispatch({
		type: journeyActions.UPDATE_MEETINGS_REMINDER,
		payload: meetingReminders,
	});
}

export function getNodeDays() {
	const { journeyBuilderJSON } = store.getState().Journeys,
		{ nodes } = journeyBuilderJSON;
	return Object.keys(nodes).map((node) => {
		if (nodes[node].type === dayNodeType) {
			const day = getCurrentNode(nodes[node]);
			return {
				id: day,
				title: getDueTitle(day),
			};
		}
		return null;
	});
}

export function getNodeTemplateDays() {
	const { journeyBuilderJSON } = store.getState().AdminJourney,
		{ nodes } = journeyBuilderJSON;
	return Object.keys(nodes).map((node) => {
		if (nodes[node].type === dayNodeType) {
			const day = getCurrentNode(nodes[node]);
			return {
				id: day,
				title: getDueTitle(day),
			};
		}
		return null;
	});
}

export function checkDate(date) {
	// To calculate days different current date to given date.
	return moment(date).diff(moment().startOf("day"), "day");
}

export const validateName = () => ({
	validator(rule, value) {
		if (value) {
			let newValue = value.trim();
			if (newValue !== "" && newValue.length < 4) {
				return Promise.reject(
					<>
						<IntlMessages id={`label.${camelCase(rule.field)}`} />{" "}
						<IntlMessages id={"message.minLength"} />
					</>,
				);
			}
		}
		return Promise.resolve();
	},
});

export const validateCustomField = () => ({
	validator(rule, value) {
		if (value) {
			let newValue = value.trim();
			if (newValue !== "" && newValue.length < 4) {
				return Promise.reject(
					<>
						{"Field name "}
						<IntlMessages id={"message.minLength"} />
					</>,
				);
			}
		}
		return Promise.resolve();
	},
});

export const validateCustomFieldTokenName = () => ({
	validator(rule, value) {
		if (value) {
			let newValue = value.trim();
			if (newValue.includes(":")) {
				return Promise.reject(
					<>
						{"Field name "}
						<IntlMessages id={"message.noColon"} />
					</>,
				);
			}
		}
		return Promise.resolve();
	},
});

export function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
}

export function findDiffDate(dueDate) {
	const dueDateMoment = moment(dueDate),
		today = moment();
	// returning 1 if due date is today and returning date diff + 2 if due date is not today since moment returns date diff 0 for today and day after today
	if (dueDateMoment.isSame(moment(), "day")) {
		return 1;
	} else {
		const dateDiff = dueDateMoment.diff(today, "days");
		if (dateDiff > -1) {
			return dateDiff + 2;
		} else {
			return dateDiff;
		}
	}
}

export function removeUploadedFiles(ids, isEmployee) {
	store.dispatch({
		type: employeeActions.REMOVE_UPLOADED_FILES,
		ids,
		isEmployee,
	});
}

export function multipleFileUpload(currentFiles, journeyEventId, updateAction) {
	let updatedFiles = [];
	for (const file of currentFiles) {
		let reader = new FileReader();
		reader.readAsArrayBuffer(file);
		reader.onload = function (event) {
			const { name, size } = file,
				fileData = reader.result;
			let md5 = CryptoJS.MD5(CryptoJS.lib.WordArray.create(fileData)),
				base64 = md5.toString(CryptoJS.enc.Base64),
				blob = new Blob([file], { type: file.type }),
				data = {
					blob: {
						filename: name,
						checksum: base64,
						content_type: file.type,
						byte_size: size,
					},
					fileData: blob,
				};
			store.dispatch({
				type: employeeActions.UPLOAD_PROFILE_IMAGE,
				payload: data,
				callBackAction: (signedId) => {
					updatedFiles.push(signedId);
					if (currentFiles.length === updatedFiles.length) {
						store.dispatch({
							type: updateAction,
							payload: { files: updatedFiles },
							id: journeyEventId,
						});
					}
				},
			});
		};
	}
}

export function removeAllFiles(isEmployee) {
	const { taskDetails } = isEmployee
			? store.getState().Employee
			: store.getState().Task,
		removeIds = taskDetails.files.map((file) => file.attachment_id);
	removeUploadedFiles(removeIds, isEmployee);
}

export function removeSingleFile(index, isEmployee) {
	const { taskDetails } = isEmployee
		? store.getState().Employee
		: store.getState().Task;
	const id = taskDetails.files[index].attachment_id;
	removeUploadedFiles([id], isEmployee);
}

export function searchMeeting(page, searchText, initialFetch) {
	store.dispatch({ type: employeesActions.SET_MEETINGS_PAGE, payload: page });
	store.dispatch({
		type: employeesActions.SET_MEETINGS_SEARCH,
		payload: searchText,
	});
	store.dispatch({
		type: employeesActions.FETCH_MEETING,
		initialFetch,
		isSearch: true,
	});
}

export function searchTask(page, searchText, initialFetch) {
	store.dispatch({
		type: employeesActions.EMPLOYEES_SET_TASK_PAGE,
		payload: page,
	});
	store.dispatch({
		type: employeesActions.EMPLOYEES_SET_TASK_SEARCH,
		payload: searchText,
	});
	store.dispatch({
		type: employeesActions.FETCH_ALL_TASKS,
		initialFetch,
		isSearch: true,
	});
}

export function showSelectionPopup(isEmployee) {
	const { taskDetails } = isEmployee
		? store.getState().Employee
		: store.getState().Task;
	if (taskDetails.files.length < 10) {
		store.dispatch({
			type: employeeActions.SET_SHOW_SELECTION_MODAL,
			payload: true,
		});
	} else {
		alert(getLocaleMessages("employee.uploadLimit"));
	}
}

export function generateBlob(file, reader) {
	const { name, size } = file,
		fileData = reader.result;
	let md5 = CryptoJS.MD5(CryptoJS.lib.WordArray.create(fileData)),
		base64 = md5.toString(CryptoJS.enc.Base64),
		blob = new Blob([file], { type: file.type });
	return {
		blob: {
			filename: name,
			checksum: base64,
			content_type: file.type,
			byte_size: size,
		},
		fileData: blob,
	};
}

export function generateRandomNumber() {
	return new Date().getTime();
}

export function generatePreview(filesArray, isEmployee) {
	let iframe = document.getElementById("task-page-frame"),
		removeElement = iframe.contentDocument.getElementById("sq-remove-upload"),
		textElement = iframe.contentDocument.getElementById("sq-upload-text"),
		uploadText = "Click here to upload files",
		previewElement = iframe.contentDocument.getElementById("sq-file-preview"),
		previewContainer =
			iframe.contentDocument.getElementById("preview-container"),
		totalFiles = iframe.contentDocument.getElementById("sq-total-files"),
		fileList = "",
		fileLength = filesArray.length,
		textS = fileLength > 1 ? "s" : "",
		fileText = uploadText;
	if (fileLength > 0) {
		fileText = `${fileLength} file${textS}`;
		removeElement.style.display = "inline-block";
		totalFiles.style.display = "inline-block";
		previewContainer.style.display = "block";
		previewContainer.style.border = "1px solid rgb(204, 204, 204)";
		previewContainer.style.borderTop = 0;
	} else {
		previewContainer.style.border = 0;
		removeElement.style.display = "none";
		totalFiles.style.display = "none";
	}
	textElement.innerHTML = '<span class="sq-add-more">+ Add</span>';
	totalFiles.innerHTML = fileText;
	for (let [index, file] of filesArray.entries()) {
		const fileType = file.content_type || file.type;
		fileList += `<div class="sq-preview-element"><img src=${getTaskPreviews(
			file,
		)} height="30" width="30"/><span>${
			file.name
		}</span><a style="margin-right: 10px" href=${file.url} target=${
			fileType.includes("pdf") ? "_blank" : "_self"
		} download><img src="https://cdn1.iconfinder.com/data/icons/arrows-vol-1-4/24/download_1-512.png" style="height:18px;width: 18px;" /></a><img src="https://s3.amazonaws.com/development.gosquadsy.com/close.png" class="sq-remove-file" fileId=${
			file.attachment_id
		} index=${index}/></div>`;
	}
	previewElement.innerHTML = fileList;
	let removeIcons =
		iframe.contentDocument.getElementsByClassName("sq-remove-file");
	for (let icon = 0; icon < removeIcons.length; icon++) {
		removeIcons[icon].addEventListener("click", function (event) {
			if (window.confirm("Are you sure to remove the file?")) {
				let index = parseInt(event.target.getAttribute("index"));
				if (index > -1) {
					removeSingleFile(index, isEmployee);
					filesArray.splice(index, 1);
					generatePreview(filesArray, isEmployee);
					if (filesArray.length === 0) {
						textElement.innerHTML = uploadText;
					}
				}
			}
		});
	}
}
export function getTaskPreviews(file) {
	const fileType = file.content_type || file.type;
	if (fileType.includes("image/")) {
		return file.url ? file.url : URL.createObjectURL(file);
	} else if (fileType.includes("pdf")) {
		return pdfImage;
	} else if (fileType === "text/plain") {
		return textFileImage;
	} else if (fileType === "text/html") {
		return htmlFileImage;
	} else {
		return attachmentImage;
	}
}

export function timeDifference(givenTime, displayAgo = false) {
	givenTime = new Date(givenTime);
	const milliseconds = new Date().getTime() - givenTime.getTime();
	const numberEnding = (number) => (number > 1 ? "" : "");
	const description = displayAgo ? ` ${getLocaleMessages("label.ago")}` : "";
	const number = (num) => (num > 9 ? `${num}` : `0${num}`);
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const getTime = () => {
		let temp = Math.floor(milliseconds / 1000);
		const years = Math.floor(temp / 31536000);
		if (years) {
			const month = months[givenTime.getUTCMonth()];
			const day = number(givenTime.getUTCDate());
			const year = givenTime.getUTCFullYear();
			return `${month} ${day}, ${year}`;
		}
		const days = Math.floor((temp %= 31536000) / 86400);
		if (days) {
			if (days < 28) {
				if (days < 7) {
					return `${days}d${numberEnding(days)}${description}`;
				} else {
					const week = Math.floor(days / 7);
					return `${week}w${numberEnding(week)}${description}`;
				}
			} else {
				const month = months[givenTime.getUTCMonth()];
				const day = number(givenTime.getUTCDate());
				return `${month} ${day}`;
			}
		}
		const hours = Math.floor((temp %= 86400) / 3600);
		if (hours) {
			return `${hours}h${numberEnding(hours)}${description}`;
		}
		const minutes = Math.floor((temp %= 3600) / 60);
		if (minutes) {
			return `${minutes}m${numberEnding(minutes)}${description}`;
		}
		return getLocaleMessages("label.justNow");
	};
	return getTime();
}

export function padStart(number) {
	return String(number).padStart(2, "0");
}

export function isJourneyAssigned() {
	const { journeyBuilderData } = store.getState().Journeys;
	return (
		journeyBuilderData.status === `${approveText}d` &&
		journeyBuilderData.assigned
	);
}
export function scrollToBottom() {
	const scrollElement = document.querySelector(".message-container");
	if (scrollElement) {
		scrollElement.scrollTop = scrollElement.scrollHeight;
	}
}

export function submitTaskForm(values, journeyEventId, updateAction) {
	store.dispatch({
		type: updateAction,
		payload: { form_data: values },
		id: journeyEventId,
	});
}

export function setDisplayWelcomePage(value) {
	store.dispatch({
		type: authActions.SET_DISPLAY_WELCOME_PAGE,
		payload: value,
	});
}
export function setOpenTasksScroll(containerId) {
	let lastTaskId = null,
		taskPage = 1;
	const element = document.querySelector(
		`#${containerId} .ant-spin-nested-loading .ant-spin-container`,
	);
	element.addEventListener("scroll", function (event) {
		const { userOpenTasks } = store.getState().Task;
		const { scrollHeight, clientHeight, scrollTop } = event.target;
		const maxScroll = scrollHeight - clientHeight;
		if (parseInt(scrollTop) && maxScroll === parseInt(scrollTop)) {
			if (containerId === "overdue-tasks") {
				// TODO: Pagination will be added once added in BE side
				// if (overDueTasks.length > 0) {
				//   const currentLastOverDueTaskId =
				//     overDueTasks[overDueTasks.length - 1].id;
				//   if (lastOverDueTaskId !== currentLastOverDueTaskId) {
				//     lastOverDueTaskId = currentLastOverDueTaskId;
				//     const pageCount = overDueTaskPage + 1;
				//     overDueTaskPage = pageCount;
				//     store.dispatch({
				//       type: taskActions.SET_OVER_DUE_TASK_PAGINATION,
				//       payload: pageCount,
				//     });
				//     store.dispatch({
				//       type: taskActions.FETCH_OVERDUE_TASKS,
				//       isInfinite: true,
				//     });
				//   }
				// }
			} else {
				if (userOpenTasks.length > 0) {
					const currentLastTaskId = userOpenTasks[userOpenTasks.length - 1].id;
					if (lastTaskId !== currentLastTaskId) {
						lastTaskId = currentLastTaskId;
						const pageCount = taskPage + 1;
						taskPage = pageCount;
						store.dispatch({
							type: taskActions.SET_TASK_PAGINATION,
							payload: pageCount,
						});
						store.dispatch({
							type: taskActions.FETCH_USER_TASKS,
							status: "started",
							isInfinite: true,
						});
					}
				}
			}
		}
	});
}

export const multipleFileUploadAsset = debounce((currentFiles, type) => {
	let updatedFiles = [];
	let progressStatus = Math.ceil(60 / currentFiles.length);
	for (const file of currentFiles) {
		let reader = new FileReader();
		reader.readAsArrayBuffer(file);
		reader.onload = function (event) {
			const { name, size } = file,
				fileData = reader.result;
			let md5 = CryptoJS.MD5(CryptoJS.lib.WordArray.create(fileData)),
				base64 = md5.toString(CryptoJS.enc.Base64),
				blob = new Blob([file], { type: file.type }),
				data = {
					blob: {
						filename: name,
						checksum: base64,
						content_type: file.type,
						byte_size: size,
					},
					fileData: blob,
				};
			store.dispatch({
				type: assetActions.UPLOAD_ASSET_IMAGE,
				payload: data,
				callBackAction: (signedId) => {
					updatedFiles.push(signedId);
					store.dispatch({
						type: assetActions.SET_ASSET_FILE_PROGRESS_STATUS,
						payload: 20 + updatedFiles.length * progressStatus,
					});
					if (currentFiles.length === updatedFiles.length) {
						store.dispatch({
							type: assetActions.SET_ASSET_FILE_PROGRESS_STATUS,
							payload: 80,
						});
						store.dispatch({
							type:
								type === "email"
									? assetActions.UPDATE_EMAIL_OR_MESSAGE_CONTENT
									: type === "template"
									? taskTemplateActions.UPDATE_ADMIN_TASK
									: assetActions.UPDATE_TASK,
							payload: { files: updatedFiles },
						});
					}
				},
			});
		};
	}
}, searchTimeout);

export function completeTask(journeyEventId, isEmployee) {
	if (isEmployee) {
		store.dispatch({
			type: employeeActions.UPDATE_EMPLOYEE_TASK,
			payload: {
				employee_status: "completed",
			},
			id: journeyEventId,
		});
	} else {
		store.dispatch({
			type: actions.UPDATE_USER_TASK,
			payload: {
				user_status: "completed",
			},
			id: journeyEventId,
		});
	}
}

export function generateAssignTo(assignTo) {
	const {
			roles,
			users,
			customRelationsList,
			microsoftTeamUsers,
			slackUsers,
			userChannels,
		} = store.getState().Journeys,
		roleNames = roles.filter(
			(role) =>
				assignTo.includes(startCase(role.name)) &&
				role.name.toLowerCase() !== "employee",
		),
		userNames = users.filter((user) => assignTo.includes(user.email)),
		customRelations = customRelationsList.filter((relation) =>
			assignTo.includes(startCase(relation.field_name)),
		),
		assignedSlackUsers = slackUsers.filter((slackUser) =>
			assignTo.includes(slackUser.slack_user_id),
		),
		assignedSlackChannels = userChannels.filter((userChannel) =>
			assignTo.includes(userChannel.id),
		),
		assignedTeamsUsers = microsoftTeamUsers.filter((teamUser) =>
			assignTo.includes(teamUser.teams_user_id),
		);
	return {
		employee: assignTo.some(
			(assignTo) => assignTo.toLowerCase() === "employee",
		),
		role_ids: roleNames.map((role) => role.id),
		user_ids: userNames.map((user) => user.id),
		relation_ids: customRelations.map((relation) => relation.id),
		slack_ids: assignedSlackUsers.map((slackUser) => slackUser.id),
		team_ids: assignedTeamsUsers.map((teamsUser) => teamsUser.id),
		slack_channels: assignedSlackChannels,
	};
}

export function generateSlackAssignTo(assignTo) {
	const { roles, slackUsers } = store.getState().Journeys,
		roleNames = roles.filter(
			(role) =>
				assignTo.includes(startCase(role.name)) &&
				role.name.toLowerCase() !== "employee",
		),
		userNames = slackUsers.filter((user) => assignTo.includes(user.id));
	return {
		employee: assignTo.some((assignTo) =>
			typeof assignTo === "string"
				? assignTo.toLowerCase() === "employee"
				: null,
		),
		role_ids: roleNames.map((role) => role.id),
		user_ids: userNames.map((user) => user.id),
	};
}

export function generateTicketingAssignTo(assignTo) {
	const { roles, ticketingUsers } = store.getState().Journeys,
		roleNames = roles.filter(
			(role) =>
				assignTo.includes(startCase(role.name)) &&
				role.name.toLowerCase() !== "employee",
		),
		userNames = ticketingUsers.filter((user) => assignTo.includes(user.id));
	return {
		employee: assignTo.some((assignTo) =>
			typeof assignTo === "string"
				? assignTo.toLowerCase() === "employee"
				: null,
		),
		role_ids: roleNames.map((role) => role.id),
		user_ids: userNames.map((user) => user.id),
	};
}

export function setMeetingTemplateReminders(currentDay) {
	// generate meeting reminder options from journey length
	const days = getNodeDays(),
		meetingReminders = days.filter((day) => day && day.id < currentDay);
	store.dispatch({
		type: journeyTemplateActions.UPDATE_MEETINGS_TEMPLATE_REMINDER,
		payload: meetingReminders,
	});
}

export function setTaskTemplateDues(currentDay) {
	// generate task due options from journey length
	const days = getNodeTemplateDays(),
		taskDues = days.filter((day) => day && day.id >= currentDay);
	store.dispatch({
		type: journeyTemplateActions.UPDATE_TASK_TEMPLATE_DUES,
		payload: taskDues,
	});
}

export function getModuleTemplateFields() {
	const { editModuleData } = store.getState().AdminJourney;

	return [
		{
			name: ["asset"],
			value: isHaveModuleTemplate(editModuleData.assetId),
		},
		{
			name: ["scheduledTime"],
			value: editModuleData.time
				? getMomentTime(editModuleData.time, hour12TimeFormat)
				: "",
		},
		{
			name: ["taskDue"],
			value: isValid(editModuleData.taskDue)
				? editModuleData.taskDue.toString()
				: undefined,
		},
		{
			name: ["reminder"],
			value: editModuleData.reminder,
		},
		{
			name: ["beforeDue"],
			value:
				editModuleData.beforeDue && isValid(editModuleData.beforeDue)
					? editModuleData.beforeDue
					: undefined,
		},
		{
			name: ["afterDueFrequency"],
			value:
				editModuleData.afterDueFrequency &&
				isValid(editModuleData.afterDueFrequency)
					? editModuleData.afterDueFrequency
					: undefined,
		},
		{
			name: ["meetingName"],
			value: editModuleData.moduleName,
		},
		{
			name: ["description"],
			value: editModuleData.meetingDescription,
		},
		{
			name: ["location"],
			value: editModuleData.location,
		},
		{
			name: ["duration"],
			value:
				editModuleData.time && editModuleData.endTime
					? [
							getMomentTime(editModuleData.time, hour12TimeFormat),
							getMomentTime(editModuleData.endTime, hour12TimeFormat),
					  ]
					: undefined,
		},
		{
			name: ["custom_emails"],
			value: editModuleData.customEmails,
		},
	];
}

export function isHaveModuleTemplate(editAssetId) {
	const { createdModule } = store.getState().AdminJourney;
	if (isValid(createdModule.id)) {
		return createdModule.id ? createdModule.id.toString() : undefined;
	} else {
		return isValid(editAssetId) ? editAssetId.toString() : undefined;
	}
}

export function searchTasksTemplate(
	page,
	searchText,
	initialFetch,
	moduleContent,
) {
	store.dispatch({ type: journeyTemplateActions.SET_TASK_PAGE, payload: page });
	store.dispatch({
		type: journeyTemplateActions.SET_TASK_SEARCH,
		payload: searchText,
	});
	store.dispatch({
		type: journeyTemplateActions.FETCH_JOURNEY_TEMPLATE_TASKS,
		initialFetch,
		isSearch: true,
		moduleContent,
	});
}

export function setSelectedTemplate(value) {
	store.dispatch({
		type: journeyTemplateActions.UPDATE_SELECTED_ASSET,
		payload: value || {},
	});
}

export function searchAssetsTemplate(
	page,
	searchText,
	initialFetch,
	moduleContent,
) {
	store.dispatch({
		type: journeyTemplateActions.SET_ASSET_PAGE,
		payload: page,
	});
	store.dispatch({
		type: journeyTemplateActions.SET_ASSET_SEARCH,
		payload: searchText,
	});
	store.dispatch({
		type: journeyTemplateActions.FETCH_ASSETS_TEMPLATE,
		initialFetch,
		isSearch: true,
		moduleContent,
	});
}

export const validateEmail = ({ getFieldValue }) => ({
	validator(rule, value) {
		if (value) {
			let newValue = value.trim();
			if (newValue !== "" && !value.match(emailRegex)) {
				return Promise.reject(
					<>
						<InjectMessage id={"message.invalid"} />{" "}
						<InjectMessage id={"label.email"} />
					</>,
				);
			}
		}
		return Promise.resolve();
	},
});

export function isEmployeeApp(subDomain) {
	return subDomain && subDomain !== "app";
}

export function showDeleteRoleConfirm(visible) {
	store.dispatch({
		type: rolesActions.SHOW_DELETE_CONFIRM_MODAL,
		payload: visible,
	});
}

export function getPermission(module, action) {
	const { userPermissions } = store.getState().Auth;
	return userPermissions.some(
		(permission) =>
			permission.policy_name.toLowerCase() === module &&
			permission.action_name === action,
	);
}

export function getMenus(menus, role) {
	// super admin role have only permitted routes in react router config, so no need of permission restrictions for super admin
	if (role !== appSuperAdmin) {
		const { userPermissions } = store.getState().Auth;
		let filteredMenus = [];
		for (const menu of menus) {
			if (
				userPermissions.some(
					(permission) => permission.policy_name === menu.policy_name,
				)
			) {
				filteredMenus = filteredMenus.concat(menu);
			}
		}
		const portalPermission = userPermissions.some(
				(permission) => permission.policy_name === portalText,
			),
			companyPermission = userPermissions.some(
				(permission) => permission.policy_name === companyText,
			),
			userPermission = userPermissions.some(
				(permission) => permission.policy_name === userText,
			),
			rolePermission = userPermissions.some(
				(permission) => permission.policy_name === roleText,
			);
		if (portalPermission || companyPermission) {
			filteredMenus = filteredMenus.concat(
				menus.filter((menu) => menu.policy_name === "portal"),
			);
		}
		if (userPermission || rolePermission) {
			filteredMenus = filteredMenus.concat(
				menus.filter((menu) => menu.policy_name === "settings"),
			);
		}
		return filteredMenus;
	}
	return menus;
}

export function searchEmployees(page, searchText) {
	store.dispatch({
		type: meetingActions.MEETINGS_SET_EMPLOYEE_PAGE,
		payload: page,
	});
	store.dispatch({
		type: meetingActions.MEETINGS_SET_EMPLOYEES_SEARCH_TEXT,
		payload: searchText,
	});
	store.dispatch({
		type: meetingActions.MEETINGS_GET_EMPLOYEES,
		isSearch: true,
	});
}

export function calculateTimeDifference(time) {
	if (time?.length === 2) {
		// calculate time diffrence between two times
		let duration = time[1].diff(time[0], "minutes");
		// sometimes the time difference is not accurate, this will make accurate minutes
		duration = duration % 5 === 0 ? duration : duration + 1;
		return `${padStart(Math.floor(duration / 60))} : ${padStart(
			duration % 60,
		)}`;
	} else if (time?.length !== 0) {
		let duration = time?.diff(moment().format("llll"), "minutes");
		return (duration = duration % 5 === 0 ? duration : duration + 1);
	}
	return "";
}

export const durationValidation = ({ getFieldValue }) => ({
	validator(rule, value) {
		const currentDate = getFieldValue("scheduled_at");
		if (value && currentDate) {
			let userDateHours = moment(value[0]);
			let currentDateHours = moment();
			if (
				currentDate.isSame(currentDateHours, "day") &&
				userDateHours.diff(currentDateHours, "minutes") < 30
			) {
				return Promise.reject(
					<InjectMessage id={"message.invalidMeetingTime"} />,
				);
			}
		}
		return Promise.resolve("");
	},
});

export const journeyDurationValidation = ({ getFieldValue }) => ({
	validator(rule, value) {
		return Promise.resolve("");
	},
});
export function searchMessages(isTemplate) {
	store.dispatch({
		type: assetActions.SET_MESSAGE_TABLE_CURRENT_PAGE,
		payload: defaultPage,
	});
	store.dispatch({
		type: assetActions.GET_MESSAGE_LIST,
		isTemplate,
	});
}
export function searchAssetTask(isTemplate) {
	store.dispatch({
		type: assetActions.SET_TASK_TABLE_CURRENT_PAGE,
		payload: defaultPage,
	});
	store.dispatch({
		type: assetActions.FETCH_TASKS,
		isTemplate,
	});
}
export function searchPages(isTemplate) {
	store.dispatch({
		type: assetActions.SET_PAGES_TABLE_CURRENT_PAGE,
		payload: defaultPage,
	});
	store.dispatch({
		type: assetActions.GET_PAGES_LIST,
		isTemplate,
	});
}
export function getAssetSearchDisable(assetActiveList, templateActiveList) {
	// disable the asset search when table is empty
	const { assetsMessageList, assetsPagesList, tasksTemplateList, tasksList } =
		store.getState().Assets;
	if (assetActiveList === assetMessage) {
		return !assetsMessageList?.length;
	} else if (assetActiveList === assetPage) {
		return !assetsPagesList?.length;
	} else if (assetActiveList === assetTask) {
		return !tasksList.length;
	} else if (assetActiveList === assetTemplate) {
		if (templateActiveList === assetMessage) {
			return !assetsMessageList?.length;
		} else if (templateActiveList === assetPage) {
			return !assetsPagesList?.length;
		} else {
			return !tasksTemplateList?.length;
		}
	}
}

export function getDashboardPermissions(action) {
	if (
		getPermission(importText, permissionManage) &&
		["handleImportModal", "gotoImportList"].includes(action)
	) {
		return true;
	} else if (
		getPermission(journeyText, permissionManage) &&
		action === "handleCreateJourneyModal"
	) {
		return true;
	}
	return false;
}

export const validateCustomEmail = ({ getFieldValue }) => ({
	validator(rule, values) {
		if (values) {
			for (let value of values) {
				if (value) {
					let newValue = value.trim();
					if (
						(newValue !== "" && !value.match(emailRegex)) ||
						newValue === ""
					) {
						return Promise.reject(
							<>
								<IntlMessages id={"message.invalid"} />{" "}
								<IntlMessages id={"label.email"} />
							</>,
						);
					}
				}
			}
		}
		return Promise.resolve();
	},
});

export function generateDuplicateName(name) {
	return `${name} #${new Date().valueOf()}`;
}

export function setSelectTask(id) {
	store.dispatch({
		type: employeesActions.EMPLOYEES_SET_SELECTED_TASK,
		payload: id,
	});
}

export function getUpComingMeetingDate(date, format) {
	return moment(moment(date).toDate()).format(format);
}
export function setActivityLogsScroll() {
	const container = document.getElementById("activity-log");
	container.addEventListener("scroll", function (event) {
		const { scrollHeight, clientHeight, scrollTop } = event.target,
			maxScroll = scrollHeight - clientHeight,
			{ activityLogs, isGettingEmployeeLog, lastLogId, activityLogPage } =
				store.getState().Employees;
		if (parseInt(scrollTop) && maxScroll === parseInt(scrollTop)) {
			if (activityLogs.length > 0) {
				const currentLastLogId = activityLogs[activityLogs.length - 1].id;
				if (lastLogId !== currentLastLogId && !isGettingEmployeeLog) {
					const pageCount = activityLogPage + 1;
					store.dispatch({
						type: employeesActions.SET_ACTIVITY_LOG_PAGE,
						payload: pageCount,
					});
					store.dispatch({
						type: employeesActions.SET_CURRENT_LAST_LOG_ID,
						payload: currentLastLogId,
					});
					store.dispatch({
						type: employeesActions.GET_EMPLOYEE_ACTIVITY_LOG,
						infiniteScroll: true,
					});
				}
			}
		}
	});
}

export const stakeholdersData = () => {
	const { customRelationsList } = store.getState().Journeys;
	let key;
	const data = [];
	const defaultStakeholders = ["Employee", "Manager", "Buddy"];
	customRelationsList.map((relation) => {
		data.push({
			key: relation.id.toString(),
			name: relation.field_name,
		});
		key = relation.id;
	});
	if (!key) {
		key = 0;
	}

	defaultStakeholders.forEach((stakeholder) => {
		data.push({
			key: (++key).toString(),
			name: stakeholder,
		});
	});
	data.sort((a, b) => {
		return a.name.toLowerCase() <= b.name.toLowerCase() ? -1 : 1;
	});
	return data;
};

export function convertHour_24_to_12(time) {
	if (time !== undefined) {
		let [hours, minutes] = time.split(":");
		if (hours === "12") {
			hours = "00";
		}
		let updateHourAndMin;
		function addition(hoursOrMin) {
			updateHourAndMin =
				hoursOrMin.length < 2 ? (hoursOrMin = `${0}${hoursOrMin}`) : hoursOrMin;

			return updateHourAndMin;
		}
		if (hours > 12) {
			hours = parseInt(hours, 10) - 12;
			return `${addition(hours)}:${addition(minutes)} PM`;
		} else {
			return `${addition(hours)}:${addition(minutes)} AM`;
		}
	}
}

export function firstLetterUpperCase(text) {
	return text.charAt(0).toUpperCase() + text.slice(1);
}

export function convertDateInputToDate(value) {
	return new Date(value).toLocaleDateString("zh-Hans-CN", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
	});
}

export const stackHoldersData = [
	{
		key: "1",
		name: "Employees",
	},
	{
		key: "2",
		name: "Manager",
	},
	{
		key: "3",
		name: "Buddy",
	},
];

let confetti = [];

const randomRange = (min, max) => Math.random() * (max - min) + min;

export const initConfetti = () => {
	let canvas = document.getElementById("canvas");
	canvas.width = window.innerWidth;
	canvas.height = 1980;
	const confettiCount = 50;
	const colors = [
		{ front: "red", back: "darkred" },
		{ front: "green", back: "darkgreen" },
		{ front: "blue", back: "darkblue" },
		{ front: "yellow", back: "darkyellow" },
		{ front: "orange", back: "darkorange" },
		{ front: "pink", back: "darkpink" },
		{ front: "purple", back: "darkpurple" },
		{ front: "turquoise", back: "darkturquoise" },
	];
	for (let i = 0; i < confettiCount; i++) {
		confetti.push({
			color: colors[Math.floor(randomRange(0, colors.length))],
			dimensions: {
				x: randomRange(10, 20),
				y: randomRange(10, 30),
			},

			position: {
				x: randomRange(0, canvas.width),
				y: -10,
			},

			rotation: randomRange(0, 2 * Math.PI),
			scale: {
				x: 1,
				y: 1,
			},

			velocity: {
				x: randomRange(-25, 25),
				y: randomRange(0, -50),
			},
		});
	}
};

export const renderConfetti = () => {
	let canvas = document.getElementById("canvas");
	const ctx = canvas.getContext("2d");
	const gravity = 0.5;
	const terminalVelocity = 5;
	const drag = 0.075;
	ctx.clearRect(0, 0, canvas.width, canvas.height);

	confetti.forEach((confetto, index) => {
		let width = confetto.dimensions.x * confetto.scale.x;
		let height = confetto.dimensions.y * confetto.scale.y;

		// Move canvas to position and rotate
		ctx.translate(confetto.position.x, confetto.position.y);
		ctx.rotate(confetto.rotation);

		// Apply forces to velocity
		confetto.velocity.x -= confetto.velocity.x * drag;
		confetto.velocity.y = Math.min(
			confetto.velocity.y + gravity,
			terminalVelocity,
		);
		confetto.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random();

		// Set position
		confetto.position.x += confetto.velocity.x;
		confetto.position.y += confetto.velocity.y;

		// Delete confetti when out of frame
		if (confetto.position.y >= canvas.height) confetti.splice(index, 1);

		// Loop confetto x position
		if (confetto.position.x > canvas.width) confetto.position.x = 0;
		if (confetto.position.x < 0) confetto.position.x = canvas.width;

		// Spin confetto by scaling y
		confetto.scale.y = Math.cos(confetto.position.y * 0.1);
		ctx.fillStyle =
			confetto.scale.y > 0 ? confetto.color.front : confetto.color.back;

		// Draw confetti
		ctx.fillRect(-width / 2, -height / 2, width, height);

		// Reset transform matrix
		ctx.setTransform(1, 0, 0, 1, 0, 0);
	});

	// Fire off another round of confetti
	if (confetti.length <= 10) initConfetti();

	window.requestAnimationFrame(renderConfetti);
};

function random(num) {
	return Math.floor(Math.random() * num);
}

function getRandomStyles() {
	const r = random(255);
	const g = random(255);
	const b = random(255);
	var mt = random(200);
	var ml = random(900);
	var dur = random(10) + 15;
	return `
  background-color: rgba(${r},${g},${b},0.7);
  color: rgba(${r},${g},${b},0.7); 
  box-shadow: inset -7px -3px 10px rgba(${r - 10},${g - 10},${b - 10},0.7);
  margin: ${mt}px 0 0 ${ml}px;
  animation: float ${dur}s ease-in infinite
  `;
}

export function createBalloons(num) {
	for (let i = num; i > 0; i--) {
		setTimeout(function timer() {
			const balloonContainer = document.getElementById("balloon-container");
			const balloon = document.createElement("div");
			balloon.className = "balloon";
			balloon.style.cssText = getRandomStyles();
			balloonContainer.append(balloon);
		}, (num - i) * 1500);
	}
}

export function notifyResponse(status, description) {
	showNotification({
		type: status,
		message: status,
		description: description,
	});
}

export function convertOperatorToString(operator) {
	const conditions = [
		{ name: "LIKE", label: "contains" },
		{ name: "beginsWith", label: "begins with" },
		{ name: "endsWith", label: "ends with" },
		{ name: "doesNotContain", label: "does not contain" },
		{ name: "doesNotBeginWith", label: "does not begin with" },
		{ name: "doesNotEndWith", label: "does not end with" },
		{ name: "null", label: "is null" },
		{ name: "notNull", label: "is not null" },
		{ name: "=", label: "is" },
		{ name: "!=", label: "is not" },
		{ name: ">", label: "Greater than" },
		{ name: "<", label: "Smaller than" },
		{ name: "<=", label: "Smaller than equal to" },
		{ name: ">=", label: "Greater than equal to" },
	];

	for (let data of conditions) {
		if (operator === data.name) {
			return data.label;
		}
	}
}

export function getEmployeeLabels(label) {
	if (label === "manager_id") {
		return "Manager Name";
	} else if (label === "buddy_id") {
		return "Buddy Name";
	} else {
		return startCase(label);
	}
}

export function setEmailToIdMicrosoftUsers(emails, microsoftTeamUsers) {
	let ids = [];
	for (let email of emails) {
		for (let microsoftUser of microsoftTeamUsers) {
			if (email === microsoftUser.mail) {
				ids.push(microsoftUser.id);
			}
		}
		if (email === "Manager" || email === "Buddy" || email === "Employee") {
			ids.push(email);
		}
	}
	return ids;
}

export function setMicrosoftUserIdsToEmails(
	microsoftTeamUserIds,
	microsoftTeamUsers,
) {
	let emails = [];
	if (microsoftTeamUserIds?.length !== undefined) {
		for (let id of microsoftTeamUserIds) {
			for (let microsoftUser of microsoftTeamUsers) {
				if (id == microsoftUser.id) {
					emails.push(microsoftUser.mail);
				}
			}
			if (id === "Manager" || id === "Buddy" || id === "Employee") {
				emails.push(id);
			}
		}
	}
	return emails;
}

export const selectEmployeesPagination = (event) => {
	const { scrollLoader, filteredEmployeesPage, filterEmployeesKey } =
		store.getState().Employees;
	const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
	if (scrollTop + clientHeight >= scrollHeight && !scrollLoader) {
		store.dispatch({
			type: employeesActions.SET_SCROLL_LOADER,
			payload: {
				value: true,
			},
		});
		store.dispatch({
			type: employeesActions.FETCH_FILTERED_EMPLOYEES,
			payload: {
				key: filterEmployeesKey,
				page: filteredEmployeesPage + 1,
				isPaginationQuery: true,
			},
		});
	}
};

export const selectEmployeesSearch = debounce((value) => {
	store.dispatch({
		type: employeesActions.SET_FILTER_EMPLOYEES_DATA,
		payload: {
			page: 1,
			key: value,
		},
	});
	store.dispatch({
		type: employeesActions.SET_LOADING_FILTERED_EMPLOYEES,
		payload: {
			value: true,
		},
	});
	store.dispatch({
		type: employeesActions.FETCH_FILTERED_EMPLOYEES,
		payload: {
			key: value,
			page: 1,
			isSearchQuery: true,
		},
	});
}, searchTimeout);

export const selectEmployeesBlurHandler = () => {
	store.dispatch({
		type: employeesActions.SET_FILTER_EMPLOYEES_DATA,
		payload: {
			key: "",
			page: 1,
			onBlur: true,
		},
	});
};

export const tokenDropDownGenerator = (assetsToken, onMenuClick) => {
	const hrisTokens = [],
		atsTokens = [],
		ticketingTokens = [],
		customTokens = [],
		taskTokens = [],
		defaultTokens = [];
	assetsToken.forEach((token) => {
		token.startsWith("squadsy:")
			? defaultTokens.push(token)
			: token.startsWith("employee:")
			? hrisTokens.push(token)
			: token.startsWith("application:")
			? atsTokens.push(token)
			: token.startsWith("user:")
			? ticketingTokens.push(token)
			: token.startsWith("custom:")
			? customTokens.push(token)
			: taskTokens.push(token);
	});

	return (
		<Menu onClick={onMenuClick}>
			<Menu.ItemGroup title="Default Tokens">
				{defaultTokens.map((token) => {
					const tokenParts = token.split(":");
					return <Menu.Item key={token}>{startCase(tokenParts[1])}</Menu.Item>;
				})}
			</Menu.ItemGroup>
			<Menu.ItemGroup title="HRIS Tokens">
				{hrisTokens.map((token) => {
					const tokenParts = token.split(":");
					return (
						<Menu.Item key={token}>
							{tokenParts.map((part) => startCase(part)).join(" - ")}
						</Menu.Item>
					);
				})}
			</Menu.ItemGroup>
			<Menu.ItemGroup title="ATS Tokens">
				{atsTokens.map((token) => {
					const tokenParts = token.split(":");
					return (
						<Menu.Item key={token}>
							{tokenParts
								.splice(1)
								.map((part) => startCase(part))
								.join(" - ")}
						</Menu.Item>
					);
				})}
			</Menu.ItemGroup>
			<Menu.ItemGroup title="Ticketing Tokens">
				{ticketingTokens.map((token) => {
					const tokenParts = token.split(":");
					return (
						<Menu.Item key={token}>
							{tokenParts.map((part) => startCase(part)).join(" - ")}
						</Menu.Item>
					);
				})}
			</Menu.ItemGroup>
			<Menu.ItemGroup title="Custom Tokens">
				{customTokens.map((token) => {
					const tokenParts = token.split(":");
					return (
						<Menu.Item key={token}>
							{tokenParts
								.splice(1)
								.map((part) => startCase(part))
								.join(" - ")}
						</Menu.Item>
					);
				})}
			</Menu.ItemGroup>
			<Menu.ItemGroup title="Task Tokens">
				{taskTokens.map((token) => {
					const tokenParts = token.split(":");
					return (
						<Menu.Item key={token}>
							{tokenParts
								.splice(2)
								.map((part) => startCase(part))
								.join(" - ")}
						</Menu.Item>
					);
				})}
			</Menu.ItemGroup>
		</Menu>
	);
};
